<template>
    <b-card no-body>
      <validation-observer v-slot="{ handleSubmit }" ref="VForm">
        <b-form @submit.prevent="handleSubmit($parent.doSubmitCRUD('VForm', $refs))">
          <b-card-header>
            <b-row>
              <b-col lg="8">
                <h5 class="card-title">{{ $parent.isAdd?'Add':'Edit' }} {{ $parent.pageTitle }}</h5>
              </b-col>
            </b-row>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col lg="4">
                <b-form-group>
                  <label>Name <span class="text-danger mr5">*</span></label>
                  <b-form-input v-model="row.mct_name" placeholder="e.g. Flazz"></b-form-input>
                  <VValidate name="Name" v-model="row.mct_name" :rules="mrValidation.mct_name" />
                </b-form-group>
              </b-col>
              <b-col lg="4">
                <b-form-group label-for="payAmount">
                  <label for="payAmount">Current Amount <span class="text-danger mr5">*</span></label>
                  <b-input-group prepend="Rp.">
                    <money id="payAmount" v-model="row.mct_current_amount" v-bind="$parent.maskCurrency" placeholder="cth: 100.000" class="form-control"></money>
                  </b-input-group>
                  <VValidate name="Current Amount" v-model="row.mct_current_amount" :rules="{ required: true, min_value: 100 }" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg=4>
                <b-form-group class="">
                  <label>Status <span class="text-danger mr5">*</span></label>
                  <b-form-radio-group :options="Config.mr.StatusOptions" v-model="row[$parent.statusKey]" />
                  <VValidate name="Status" v-model="row[$parent.statusKey]" :rules="mrValidation[$parent.statusKey]" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-footer>
            <b-row>
              <b-col lg="12" class="text-right">
                <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">Cancel</button>
                <b-button type="submit" variant="info" class="btn-rounded">Submit</b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-form>
      </validation-observer>
    </b-card>
</template>
<script>
 export default {
    props: {
      row: Object,
      mrVehicleCategory: Array,
      mrValidation: Object,
      Config: Object,
    },
    methods: {
      doSubmitForm() {
        this.$emit('click:doSubmit', 'VForm', this.$refs)
      },
    }
 }
</script>
